<template>
  <div class="container">

    <p>
      <strong>Reddit Watch</strong> is a free tool for monitoring Reddit for posts containing certain keyword(s) in near
      real-time.
    </p>

      <p>
          I built a limited version of this application for my own personal use some time ago, but I have now opened it up for anyone to use within reasonable limits.
      </p>

    <h2 class="mb-3">Why?</h2>

    <p>

      <!--      <img src="https://i.redd.it/0hjboi89vwv11.png" width="400" class="float-right">-->

        One use of this is to filter down <strong>Reddit</strong> discussions to a few specific topics that you are interested in.
    </p>

      <p>
          Another potentially effective use of this is for influencing Reddit discussions in a certain way.
          <br>
      By upvoting or commenting on certain threads as soon as they are posted,
      you have a much better chance for those threads to do well - especially if you have enough people
      participating in this.

      In Reddit's algorithm, earlier votes are worth more, and that is because early and positive "upvote
      momentum" is rewarded more. Same with downvotes.
      That is why downvoting a post that's already at the top of a particular subreddit will
      have little effect, where as downvoting a post that just got posted will mean a lot more.
      </p>

      <p>
      It works the same with comments where commenting on a thread that already has hundreds of comments makes it
      very unlikely that your comment will
      gather enough momentum to move to the top or be seen by a great number of people.
      <!--

            Reddit is a <a href="">massive platform</a> with <a>many massive forums</a>
      -->

    </p>

    <div class="row mt-4">

      <div class="col-12 text-center">
        <router-link class="btn btn-lg btn-primary" :to="demoLoginUrl">
          <b-icon-chevron-right></b-icon-chevron-right>
            <b-icon-chevron-right></b-icon-chevron-right>
          See a demo
        </router-link>
      </div>

    </div>

    <hr class="my-4">

    <h2 class="mb-3">
      <b-icon-file-text></b-icon-file-text>
      API - !! NEW UPDATES !!
    </h2>

    <p>
        We use the main <strong>api.proxynova.com</strong> endpoint.
        Go to main <a href="https://www.proxynova.com/?utm_source=redditwatch" target="_blank">ProxyNova website</a> for documentation.
        The old API that was previously hosted on <strong>api.unblockvideos.com</strong> will be retiring soon.
    </p>

    <h2 class="mt-5">To-do list...</h2>

    <ul>
      <li>Better design...</li>
      <li>Improved support across devices - it's not too bad at the moment, but the app still looks much better on
        desktop than mobile.
      </li>
      <li>More notification "channels" - get notified through email or slack</li>
      <li>Would be useful to have an ability to track specific reddit users, and get notified whenever they post or make
        a comment anywhere on reddit.
      </li>
    </ul>

  </div>
</template>

<script>

import {BIconChevronRight, BIconFileText} from 'bootstrap-vue';
import store from "@/store";
import api from "@/api";

export default {
  name: 'Home',
  components: {
    BIconChevronRight,
    BIconFileText
  },
  computed: {
    demoLoginUrl() {
      return '/?access_token=nat_2CbwHQUJLJ1dQQTsIKEst3f3Y1kUYAYbYRWd';
    }
  },
  methods: {},
  async mounted() {

    // we are a guest!
    if (store.getters.isGuest) {
      // await store.dispatch('logout');
    }

    let counter = document.querySelector('#post_counter');

/*    api.stats().then(function (data) {

      let count = data['post_count']['last_hour'];
      let rate = data['post_count']['rate'];

      setInterval(function () {
        count++;
        counter.innerHTML = count.toLocaleString();

      }, Math.ceil(1000 / rate));

    });*/

  }
}
</script>

<style scoped>

</style>