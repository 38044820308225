<template>
    <div>

        <h1 class="text-center text-danger">Page not found!</h1>
    </div>
</template>

<script>
    export default {
        name: 'NotFound'
    }
</script>

<style scoped>

</style>