import Vuex from 'vuex';
import Vue from "vue";
import {UserInfo} from "@/classes/UserInfo";
import api from "@/api";
import {Util} from "@/classes/Util";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        isBusy: false,
        error: '',
        token: null,
        /** @type UserInfo */
        user: null,

        // cache this
        alerts: []
    },
    getters: {

        /**
         *
         * @param state
         * @returns {UserInfo}
         */
        user(state) {
            return state.user;
        },
        isLoggedIn: function (state) {
            return state.user !== null;
        },
        isGuest(state) {
            return state.user && !state.user.master_token
        },
        publicToken(state) {

            if (state.user) {
                return state.user.public_token;
            }
        },
        guestLoginUrl(state) {

            if (state.user) {
                return 'https://reddit.proxynova.com/#/login/' + state.user.public_token;
            }

            return null;
        }
    },
    mutations: {
        increment(state) {
            state.count++
        },
        setAuthState(state, data) {

            const {token, user} = data;

            state.token = token;
            state.user = user;
        }
    },
    actions: {

        async reloadUser(context) {

            const token = context.state.token;

            if (token) {
                await context.dispatch('loginWithToken', token);
            }
        },

        // existing token
        // if user logged in is guest -> do not "remember me" in token storage
        async loginWithToken(context, token) {

            const data = await api.getTokenInfo(token);

            if (data) {
                context.state.token = token;
                context.state.user = data;
            }

            localStorage.setItem('token', token);
        },

        async logout(context) {
            context.state.token = null;
            context.state.user = null;

            localStorage.removeItem('token');
        },

        async syncAlerts(context) {

            return new Promise(function (resolve, reject) {

                api.client.get('/v1/reddit/alerts').then(function (res) {

                    const data = res.data;
                    const alerts = data.data;

                    context.state.alerts = alerts;

                    resolve(Util.nonReactive(alerts));

                }).catch((err) => reject(err));

            });
        }
    }
});

export default store;

