export class Util {

    static async sleep(ms) {

        return new Promise(function (resolve, reject) {

            setTimeout(() => {
                resolve()
            }, ms);
        })
    }

    static nonReactive(value) {

        if (typeof value === 'object') {
            return JSON.parse(JSON.stringify(value));
        }

        return value;
    }

    static rand() {
        return Math.floor(Math.random() * 1000);
    }

    static timestampInSeconds() {
        return Math.round(Date.now() / 1000);
    }

    static randInt(min, max) {
        return Math.floor(
            Math.random() * (max - min) + min
        )
    }

    static extractErrorMessage(err) {

        if (err) {

            if (err.response && err.response.data) {

                if ('error' in err.response.data) {
                    return err.response.data.error;
                }

                return err.response.data;
            }

            if (err.message) {
                return err.message;
            }

            return err.toString();
        }

        return null;
    }

    static poll(fn, timeout, interval) {

        var endTime = Number(new Date()) + (timeout || 2000);
        interval = interval || 100;

        var checkCondition = function (resolve, reject) {
            // If the condition is met, we're done!
            var result = fn();
            if (result) {
                resolve(result);
            }
            // If the condition isn't met but the timeout hasn't elapsed, go again
            else if (Number(new Date()) < endTime) {
                setTimeout(checkCondition, interval, resolve, reject);
            }
            // Didn't match and too much time, reject!
            else {
                reject(new Error('timed out for ' + fn + ': ' + arguments));
            }
        };

        return new Promise(checkCondition);
    }
}

