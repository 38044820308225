import axios, {AxiosInstance} from 'axios';
import {UserInfo} from "@/classes/UserInfo";
import store from "@/store";

const getClient = function () {

    const token = store.state.token;

    return axios.create({
        //'baseURL': 'http://reddit.api.unblockvideos.test:8001/v1',
        // 'baseURL': 'https://reddit.api.unblockvideos.com/v1',
        // 'baseURL': 'https://api.unblockvideos.com/v1/reddit',
        baseURL: 'https://api.proxynova.com',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token
        }

    });
}

/**
 * @typedef { {token: string, user: UserInfo} } LoginResponse
 */
class Api {

    constructor() {
        // do nothing
    }

    /**
     *
     * @returns {AxiosInstance}
     */
    get client() {
        return getClient();
    }

    /**
     *
     * @param token
     * @returns {Promise<UserInfo|null>}
     */
    async getTokenInfo(token) {

        //const {data} = await getClient().get('/me', {params});
        const {data} = await getClient().get('/v1/auth/me', {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (data) {
            return UserInfo.create(data.data);
        }

        return null;
    }

    /**
     *
     * @param email
     * @param password
     * @returns {Promise<LoginResponse>}
     */
    async login(email, password) {

        const {data} = await getClient().post('auth/login', {
            email: email,
            password: password
        });

        return data;
    }

    async register(email, password) {

        const {data} = await getClient().post('auth/register', {
            email: email,
            password: password
        });

        return data;
    }

    async alerts() {
        const {data} = await getClient().get('/v1/reddit/alerts');
        return data;
    }

    async stats() {
        const {data} = await getClient().get('/v1/reddit/stats');
        return data;
    }
}

export default new Api();