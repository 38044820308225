import {ApiResponse} from "@/classes/ApiResponse";

export class UserInfo extends ApiResponse {

    name = ';'
    email = '';
    //public_token = '';
    //master_token = '';

    /**
     *
     * @type {{matches: Limit, alerts: Limit, webhooks: Limit}}
     */
    limits = {};
}