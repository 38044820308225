import store from "../store";

/**
 *
 * @param {Route} to
 * @param from
 * @param next
 * @constructor
 */
export const AuthMiddleware = function (to, from, next) {

    const name = to.name;
    const isAuthRoute = !!to.meta.auth;
    const isLoggedIn = store.getters.isLoggedIn;

    console.log(`Attempting navigating to ${name}. Auth status: ${isLoggedIn}. Status: ${store.state.isBusy}`);


    /*    store.state.isBusy = true;

        setTimeout(() => {
            store.state.isBusy = false;
            next();
        }, 1000)*/

    if ((to.name === 'posts' || to.name === 'alerts' || to.name === 'settings') && !isLoggedIn) {
        next({name: 'home'});
    } else {
        next();
    }


}