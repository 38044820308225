/**
 *
 * @param {Route} to
 * @param {Route} from
 * @param next
 */
export const setPageTitleMiddleware = function (to, from, next) {

    let pageTitle = to.meta?.title;

    if (pageTitle) {
        pageTitle += ' - Reddit Watch';
    } else {
        pageTitle = 'Reddit Watch - Monitor Reddit posts by keyword in real time!';
    }

    window.document.title = pageTitle;
    next()
}