import Vue from 'vue'
import Router from 'vue-router'
import App from './App.vue'
import {DateTime} from 'luxon';

import store from './store';

import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-vue/src/index.scss';

import './assets/style.scss';

import router from "@/router";
import {ErrorHandler} from "@/classes/ErrorHandler";
import {Session} from "@/classes/Session";
import api from "./api";

window.luxon = DateTime;

Vue.prototype.$store = store;

Vue.mixin({
    computed: {
        globalState() {
            return store.state;
        },
        /**
         *
         * @returns {UserInfo}
         */
        user() {
            return store.getters.user;
        },
        isAdmin() {
            return this.user && !this.isGuest;
        },
        isGuest() {
            return this.user && this.user.email === 'demo@redditwatch.com';
        }
    }
});

Vue.config.productionTip = false;

Vue.config.errorHandler = function (err, vm, info) {
    ErrorHandler.report(err);
}

Vue.use(Router);

Session.boot();

const token = localStorage.getItem('token');

function loadApp() {

    const app = new Vue({
        el: '#app',
        render: function (h) {
            return h(App)
        },
        router: router
    });
}

if (token) {

    api.getTokenInfo(token).then((res) => {

        store.state.token = token;
        store.state.user = res;

    }).finally(() => {
        loadApp();
    });

} else {
    loadApp();
}




