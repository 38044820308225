export class ApiResponse {

    /**
     * @param props
     * @returns {this}
     */
    static create(props) {
        let model = new this();

        if (props) {

            for (const [key, value] of Object.entries(props)) {
                model[key] = value;
            }
        }

        return model;
    }
}