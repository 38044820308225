<template>
  <div>

    <alert-modal ref="modal" :alert="alert"
                 @alert-created="alertCreated"
                 @alert-updated="alertUpdated"
    ></alert-modal>

    <div class="d-block text-center mb-4">

      <button type="button" class="btn btn-success btn-lg" @click="addAlert" :disabled="!isAdmin">
        <b-icon-plus></b-icon-plus>
        New Alert
      </button>

      <span :class="(available ? 'text-success' : 'text-danger') + ' avail pl-3'">
            {{ available }} Available
          </span> ({{ used }} in use)

    </div>

    <div class="row">
      <div class="col-12">
        <p>You are limited to around 100 post matches per hour, so keep your alerts as specific as possible.</p>
      </div>
    </div>

    <div class="card">

      <div class="card-header bg-white">
        <h5>Active Alerts</h5>
      </div>

      <b-table :items="items" :fields="fields" :busy="isBusy" head-variant="light" hover show-empty>

        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:empty>
          <div class="text-center p-4">No alerts created yet!</div>
        </template>

        <template v-slot:cell(query)="row">

          <div v-if="isAdmin">

            <a href="#" @click.prevent="editModal(row)">
              <b-icon-pencil></b-icon-pencil>
              {{ row.item.query }}
            </a>

          </div>

          <div v-else>
            {{ row.item.query }}
          </div>

        </template>

        <template v-slot:cell(actions)="row">

          <button @click="removeAlert(row)" class="btn btn-default btn-sm" :disabled="!isAdmin">
            <b-icon-trash font-scale="2" class="text-danger" v-if="!row.item.busy"></b-icon-trash>
            <b-spinner class="align-middle text-primary" v-if="row.item.busy"></b-spinner>
          </button>

        </template>

      </b-table>

    </div>

  </div>

</template>

<script>

import {BIconPencil, BIconPlus, BIconTrash, BIconPencilSquare, BSpinner, BTable} from 'bootstrap-vue';

import AlertModal from './AlertModal';
import api from "@/api";
import store from "@/store";

export default {
  name: 'Alerts',
  components: {
    AlertModal,
    BTable,
    BSpinner,
    BIconTrash,
    BIconPencil,
    BIconPlus
  },
  computed: {
    available: function () {
        return Math.max(0, 3 - this.used);
    },
    used: function () {
        return this.items.length;
    }
  },
  data: function () {
    return {
      isBusy: true,
      fields: [
        {key: 'added_ago', label: 'Added'},
        {key: 'query', label: 'Search Query'},
        {key: 'count_hour', label: '# of Posts (hour)'},
        {key: 'count_day', label: '# of Posts (day)'},
        {key: 'actions'}
      ],
      items: [],
      showModal: false,
      alert: {}
    }
  },
  watch: {
    user: {
      immediate: true,
      handler: function (newValue) {

        if (newValue) {
          this.loadAlerts();
        }
      }
    }
  },
  methods: {
    addAlert: function () {
      this.alert = {
        data: {}
      };

      this.$refs['modal'].show();
    },
    editModal: function (row) {

      this.alert = row.item.data;
      this.$refs['modal'].show();
    },
    removeAlert(row) {

      const oThis = this;
      const item = row.item;

      if (confirm('Are you sure?')) {
        item.busy = true;

        // TODO: just reload this
        api.client.delete('/v1/reddit/alerts/' + item.id).then(function () {

          oThis.items = oThis.items.filter(function (row) {
            return row.id !== item.id;
          });

        }).catch(function (err) {
          alert(err);
        }).then(function () {
          item.busy = false;
          store.dispatch('reloadUser');
        });
      }
    },
    alertCreated: function (val) {
      this.loadAlerts();
      store.dispatch('reloadUser');
    },
    alertUpdated: function () {
      this.loadAlerts();
    },
    loadAlerts: function () {

      const oThis = this;
      oThis.isBusy = true;

      store.dispatch('syncAlerts').then(function (alerts) {

        oThis.items = [];

        alerts.forEach(function (val) {
          oThis.items.push({
            busy: false,
            id: val.id,
            added_ago: window.luxon.fromISO(val.created_at).toRelative(),
            query: val.query,
            count_hour: val.matches.past_hour,
            count_day: val.matches.past_day,
            data: val
          })
        });

        oThis.isBusy = false;

      }).catch(function (err) {
        alert(err);
      });
    }
  },
  mounted: async function () {
    console.log('Alerts.vue mounted');
  }
}

</script>

<style scoped>
.avail {
  font-weight: bold;
  font-size: 1rem;
}

</style>