<template>
  <div id="app" class="d-flex flex-column">

    <navigation></navigation>

    <section class="flex-grow-1">

      <b-container class="mb-4">

        <div v-if="isBusy && !error" class="text-center">

          <div class="d-inline-flex flex-row align-items-center mt-5">
            <b-spinner label="Large Spinner" class="spinner text-info"></b-spinner>
            <h3 class="d-inline pl-3 text-info">Working...</h3>
          </div>

        </div>

        <div v-if="error">

          <div class="alert alert-danger d-flex align-items-center">
            <b-icon-exclamation-triangle-fill font-scale="2"></b-icon-exclamation-triangle-fill>
            <span class="pl-2">{{ error }}</span>
          </div>

        </div>

      </b-container>

      <div class="alert alert-warning w-75 mx-auto mb-5" v-if="user && isGuest">
        You are logged in as a <strong>Guest</strong>, so you will not be able make any changes to this account.

        If you want your own personal tracker,
        <a href="" @click.prevent="createAccount" class="font-weight-bold">create an account</a>
        first.
      </div>

      <div class="container-fluid">
        <router-view v-show="!isBusy && !error" v-if="!globalState.isBusy"></router-view>
      </div>

    </section>

    <v-footer></v-footer>

  </div>
</template>

<script>

import {BContainer, BIconExclamationTriangleFill, BSpinner,} from 'bootstrap-vue'

import store from "./store";
import VFooter from "@/components/Footer";
import Navigation from "@/components/Navigation";

export default {
  name: 'App',
  data() {
    return {}
  },
  components: {
    Navigation,
    VFooter,
    BContainer,
    BSpinner,
    BIconExclamationTriangleFill,
  },
  computed: {
    store() {
      return this.$store;
    },
    error() {
      return store.state.error;
    },
    isBusy() {
      return store.state.isBusy;
    }
  },
    methods: {
        createAccount() {
            const homeUrl = window.location.protocol + '//' + window.location.host;
            window.location = 'https://account.proxynova.com/login?return=' + encodeURIComponent(homeUrl);
        }
    },
  watch: {},
  async mounted() {
      // do nothing
  }
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans&display=swap');

.spinner {
  width: 3rem;
  height: 3rem;
}

</style>
