import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from "@bugsnag/plugin-vue";

/*
Bugsnag.start({
    apiKey: 'c2bbcb87023e3dedadbdd8348c6899dc',
    plugins: [new BugsnagPluginVue()]
});
*/

export class ErrorHandler {

    static report(err) {
        Bugsnag.notify(err);
    }
}