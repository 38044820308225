import {Util} from "@/classes/Util";

export class Session {

    stateId = 0;
    firstVisitAt = 0;
    referrer = '';

    static boot() {

        let stateId = Util.randInt(100, Number.MAX_SAFE_INTEGER);

        this.stateId = stateId;
        localStorage.setItem('state_id', '' + stateId);

        if (!localStorage.getItem('first_visit_at')) {
            localStorage.setItem('first_visit_at', '' + Util.timestampInSeconds());
        }

        if (!localStorage.getItem('ref')) {

            let ref = document.referrer;

            this.referrer = ref;
            localStorage.setItem('ref', ref);
        }
    }
}