<template>
  <div>

    <b-modal ref="modal" title="BootstrapVue" :hide-footer="true" :hide-header="true" @shown="focusQuery" size="lg">

      <div class="d-block mx-3 my-2">

        <form ref="form" @submit.stop.prevent="handleSubmit">

          <div class="form-group">
            <label for="query">Search query:</label>
            <input type="text" class="form-control" id="query" v-model="form.query" ref="query">

            <small class="form-text text-muted">Common boolean operators supported. See examples below.</small>
          </div>

          <div class="form-group">

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" v-model="form.search_title"
                     id="search_title">
              <label class="form-check-label" for="search_title">
                Search post titles
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="search_text" v-model="form.search_text">
              <label class="form-check-label" for="search_text">
                Search inside post text
              </label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="checkbox" id="search_url" v-model="form.search_url">
              <label class="form-check-label" for="search_url">
                Search in URL
              </label>
            </div>

          </div>

          <div class="form-group py-3 text-center">
            <button type="submit" class="btn btn-primary btn-block" :disabled="isBusy">
              <b-spinner small class="align-middle mr-2" v-if="isBusy"></b-spinner>
              <span>{{ button_text }}</span>
            </button>
          </div>

          <search-operators></search-operators>

        </form>

        <div class="alert alert-danger" v-if="form_error">
          {{ form_error }}
        </div>

      </div>

    </b-modal>

  </div>
</template>

<script>

import {BModal, BSpinner} from 'bootstrap-vue';
import api from "@/api";
import {Util} from "@/classes/Util";
import SearchOperators from "@/components/partials/SearchOperators";

export default {
  name: 'AlertEdit',
  components: {
    SearchOperators,
    BModal, BSpinner
  },
  props: ['showDialog', 'alert'],
  data: function () {
    return {
      isBusy: false,
      button_text: 'Save Changes',
      form_error: '',
      form: {
        query: '',
        search_title: 1,
        search_text: 0,
        search_url: 0,
      }
    }
  },
  computed: {},
  watch: {
    alert: function (newValue, oldValue) {

      /** @type {Alert} */
      let alert = newValue;

      if (alert.id) {

        this.form.query = alert.query;
        this.form.search_title = !!alert.search_title;
        this.form.search_text = !!alert.search_text;
        this.form.search_url = !!alert.search_url;

      } else {

        this.form.query = '';
        this.form.search_title = 1
        this.form.search_text = 0;
        this.form.search_url = 0;
      }

    }
  },
  methods: {
    focusQuery() {
      this.$refs['query'].focus();
    },
    show() {
      this.form_error = null;
      this.$refs['modal'].show();
    },
    hide() {
      this.$refs['modal'].hide()
    },
    enableButton: function () {
      this.isBusy = false;
      this.button_text = 'Save Changes';
    },
    disableButton: function () {
      this.isBusy = true;
      this.button_text = 'Saving...';
    },
    handleCreate: function () {

      const oThis = this;

      api.client.post('/v1/reddit/alerts', {
        query: oThis.form.query,
        search_title: oThis.form.search_title,
        search_text: oThis.form.search_text,
        search_url: oThis.form.search_url
      }).then(function (res) {

        console.log(res);

        oThis.$emit('alert-created', res.data.data);
        oThis.hide();

      }).catch(function (err) {
        oThis.form_error = Util.extractErrorMessage(err);
      }).then(function () {
        oThis.enableButton();
      });

    },
    handleUpdate: function () {

      const oThis = this;

      api.client.patch('/v1/reddit/alerts/' + this.alert.id, {
        query: oThis.form.query,
        search_title: oThis.form.search_title,
        search_text: oThis.form.search_text,
        search_url: oThis.form.search_url
      }).then(function (res) {

        console.log(res);

        oThis.$emit('alert-updated', oThis.form);
        oThis.hide();

      }).catch(function (err) {
        oThis.form_error = Util.extractErrorMessage(err);
      }).then(function () {
        oThis.enableButton();
      });

    },
    handleSubmit: function () {

      const creating = !this.alert.id;

      this.form_error = '';
      this.disableButton();

      if (creating) {
        this.handleCreate();
      } else {
        this.handleUpdate();
      }
    }
  },
  mounted: function () {

  }
}
</script>

<style scoped>

</style>