<template>
  <div class="container">

    <h2>API Access</h2>

    <p class="mb-3">
      Use this info for logging in, and for accessing our API. Read complete documentation on <strong>proxynova.com</strong>
    </p>

    <div class="form-group row">
      <label for="master_key" class="col-sm-2 col-form-label text-right">API Token:</label>
      <div class="col-sm-6">

        <input type="text" v-model="token_master" class="form-control" id="master_key"
               autocomplete="off" onclick="this.select()" readonly>

        <b-form-text>
          Provides complete read & write access to everything on this account. <strong>Keep this private</strong>.
        </b-form-text>

      </div>

      <div class="col-sm-2">

        <button class="btn btn-default btn-sm" @click="toggleMasterKey" v-if="master_hidden" :disabled="!isAdmin">
          <b-icon-eye font-scale="2"></b-icon-eye>
        </button>

        <button class="btn btn-default btn-sm" @click="toggleMasterKey" v-if="!master_hidden">
          <b-icon-eye-slash font-scale="2"></b-icon-eye-slash>
        </button>

      </div>

    </div>

<!--    <div class="form-group row">
      <label for="public_url" class="col-sm-2 col-form-label text-right">Guest URL:</label>
      <div class="col-sm-6">
        <input type="text" id="public_url" class="form-control" v-model="public_url" onclick="this.select()" readonly>

        <b-form-text>
          Use this link to access the <strong>read-only</strong> version of this app.
          Very useful when sharing the same account with multiple people.
        </b-form-text>
      </div>
    </div>-->


    <!--    <div class="col-md-8" v-if="true">
          <div class="input-group input-group-sm">

            <div class="input-group-prepend">
              <span class="input-group-text">Public Link:</span>
            </div>

            <input type="text" ref="login_link" class="form-control" onclick="this.select()"
                   :value="login_url">
          </div>

          <div class="form-text">
            <small class="text-muted">This link can be used to access a read-only version of this page.</small>
          </div>

        </div>-->


    <div class="my-5"></div>

    <webhook-modal ref="modal" st="this is static" :hook="selected_webhook"
                   @hook-created="hookCreated" @hook-updated="hookUpdated"
    ></webhook-modal>


    <h2 class="mb-3">Webhooks</h2>

    <div class="row">
      <div class="col-auto">

        <b-button variant="success" @click.prevent="addWebhook" :disabled="!isAdmin">
          <b-icon-plus></b-icon-plus>
          Add new
        </b-button>

      </div>

      <div class="col-auto">
        Send notifications to custom endpoints in (almost) real-time. Will not be sent more than once every 10
        seconds.
        <br>
        Useful place to test this: <a href="https://webhook.site" target="_blank">https://webhook.site</a>
      </div>
    </div>

    <div class="mb-3"></div>

    <b-table striped show-empty outlined :fields="fields" :items="items" :busy="isBusy">

      <template v-slot:table-busy>
        <div class="text-center text-danger p-4">
          <b-spinner class="align-middle pr-1"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:empty>
        <div class="text-center p-4">No Webhooks created yet...</div>
      </template>

      <template v-slot:cell(name)="data">

        <div v-if="isAdmin">

          <a href="#" @click.prevent="editHook(data.item)">
            <b-icon-pencil font-scale="1"></b-icon-pencil>
            {{ data.item.name }}
          </a>

        </div>

        <div v-else>
          {{ data.item.name }}
        </div>

        <div class="text-muted">{{ data.item.url }}</div>

      </template>

      <template v-slot:cell(actions)="data">

        <button @click="removeWebhook(data.item)" class="btn btn-default btn-sm" :disabled="!isAdmin">
          <b-icon-trash font-scale="2" class="text-danger"></b-icon-trash>
        </button>

        <!--          <button class="btn btn-default btn-sm">
                    <b-icon-heart font-scale="2"></b-icon-heart>
                  </button>-->

      </template>

    </b-table>

  </div>
</template>

<script>

import WebhookModal from "@/components/WebhookModal";

import {
  BButton,
  BFormText,
  BIconEye,
  BIconEyeSlash,
  BIconPencil,
  BIconPlus,
  BIconTrash,
  BSpinner,
  BTable
} from 'bootstrap-vue'
import api from "@/api";
import store from "@/store";

export default {
  name: 'Profile',
  components: {
    BTable,
    //  BFormInput,
    BFormText,
    BButton,
    // BIconPencilSquare,
    BIconTrash,
    BSpinner,
    WebhookModal,
    BIconEye,
    BIconEyeSlash,
    BIconPlus,
    BIconPencil
  },
  methods: {
    hookCreated: function (hook) {
      this.loadItems();
    },
    hookUpdated: function () {
      this.loadItems();
    },
    addWebhook: function () {
      this.selected_webhook = {};
      this.$refs['modal'].show();
    },
    editHook: function (item) {
      this.selected_webhook = item;
      this.$refs['modal'].show();
    },
    removeWebhook: function (item) {

      let oThis = this;
      let id = item.id;

      if (id && confirm('Are you sure?')) {

        oThis.isBusy = true;

        api.client.delete('/v1/reddit/webhooks/' + id)
            .then(function (res) {
              // do nothing
            })
            .catch((err) => alert(err.message))
            .finally(function () {
              oThis.loadItems();
            });
      }

    },
    loadItems: function () {

      let oThis = this;
      oThis.items = [];
      oThis.isBusy = true;

      api.client.get('/v1/reddit/webhooks').then(function (res) {
        let data = res.data.data;

        data.forEach(item => {
          item.created_at = window.luxon.fromISO(item.created_at).toRelative();
          oThis.items.push(item);
        });

      }).finally(function () {
        oThis.isBusy = false;
      })
    },
    toggleMasterKey: function () {
      this.master_hidden = !this.master_hidden;
    }
  },
  watch: {
    master_hidden: {
      immediate: true,
      handler: function (value) {

        if (value) {
          this.token_master = '--- HIDDEN ---';
        } else {
          this.token_master = localStorage.getItem('token');
        }
      }
    }
  },
  computed: {
    public_url: function () {
      return store.getters.guestLoginUrl;
    }
  },
  data: function () {
    return {
      master_hidden: true,
      token_master: '',
      isBusy: false,
      public_key: 'public key',
      private_key: 'private key',
      fields: [
        {key: 'created_at', label: 'Created'},
        {key: 'name', label: 'Name'},
        //  {key: 'url', label: 'Callback URL'},
        {key: 'actions', label: 'Actions'}
      ],
      items: [],
      selected_webhook: {}
    }
  },
  async mounted() {

    let oThis = this;
    oThis.isBusy = true;

    await store.dispatch('syncAlerts');

    this.loadItems();

  }
}
</script>

<style scoped>

</style>