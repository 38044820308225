<template>
  <footer class="text-center mt-5 border-top">

    <div class="d-inline-block text-muted small py-3">
      <strong>Disclaimer:</strong> We are not affiliated with reddit in any way. No one is working or has ever worked
      at
      reddit. All of reddit's data is gathered using
      <a href="https://www.reddit.com/dev/api/" target="_blank">official Reddit API</a>.

      <p>
          Send comments & feedback to: <a :href="`mailto:${email}`" v-text="email"></a>
      </p>

    </div>

  </footer>
</template>

<script>
export default {
  name: 'v-footer',
    data(){

      const temp = (function(){return "re"+"dd"+"i"+"t".concat(atob("QHByb3h5")).concat(atob("bm92YS5j")).concat("oom".substring(1,3))}());

      return {
          email: temp
      }
    }
}
</script>

<style scoped>

</style>