<template>
  <nav class="container-fluid py-2 border-bottom bg-white mb-5 fixed-top" id="top_nav">

    <div class="container d-flex justify-content-between align-items-center">

      <router-link to="/" class="home-button">
        RedditWatch.com <small class="text-info font-weight-bold">(1.0.0-beta)</small>
      </router-link>

      <div v-if="!isLoggedIn">

        <a href="" @click.prevent="login" class="nav-button mr-3">
          <b-icon-box-arrow-right></b-icon-box-arrow-right>
          Login / Register
        </a>

      </div>

      <div class="menu_feedback d-none d-sm-block" v-if="user">
        Hello, <span class="font-weight-bold font-italic text-primary">{{ user.email }}</span>
      </div>

      <div v-if="isLoggedIn">

        <router-link to="/posts" class="nav-button mr-3">
          <b-icon-collection></b-icon-collection>
          Posts <span class="badge badge-light" v-if="false">4</span>
        </router-link>

        <router-link to="/alerts" class="nav-button mr-3">
          <b-icon-bell></b-icon-bell>
          Manage Alerts
        </router-link>

        <router-link to="/settings" class="nav-button">
          <b-icon-gear></b-icon-gear>
          Profile
        </router-link>

        <a @click.prevent="logout" class="nav-button ml-5" :class="{active: false}">
          Logout
        </a>

      </div>

    </div>

  </nav>
</template>

<script>
import {BIconBell, BIconCollection, BIconGear, BIconBoxArrowRight} from "bootstrap-vue";
import store from "@/store";
import {Util} from "@/classes/Util";

export default {
  name: 'Navigation',
  components: {
    BIconBell,
    BIconGear,
    BIconCollection,
    BIconBoxArrowRight
  },
  computed: {
    isLoggedIn() {
      return this.user !== null;
    },
  },
  methods: {
      login(){
          const homeUrl = window.location.protocol + '//' + window.location.host;
          window.location = 'https://account.proxynova.com/login?return=' + encodeURIComponent(homeUrl);
      },
    async logout() {

      // TODO: navigation.isBusy = true
      await store.dispatch('logout');
      // await this.$router.replace('/');
      window.location = '/';
    }
  }
}
</script>

<style scoped>

#top_nav {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
}

.home-button {
  font-family: 'IBM Plex Sans', sans-serif;
  color: #ff4500;
  font-size: 1.2em;
  font-weight: 700;
}

</style>

<style scoped lang="scss">

.nav-button {
  @extend .btn, .btn-light, .btn-sm;
  @extend .px-3 !optional;
  @extend .border-secondary;

  &.active {
    @extend .bg-primary !optional;
    @extend .text-white !optional;
  }

}


</style>