<template>

  <b-modal ref="modal" :title="title" :hide-footer="true" :hide-header="true" :no-fade="true" :visible="false"
           @shown="onShow">

    <div class="d-block mx-3 my-2">

      <form ref="form" @submit.stop.prevent="submit" autocomplete="off">

        <div class="form-group">
          <label for="name">Name:</label>
          <input type="text" class="form-control" id="name" v-model="resource.name" ref="query">

          <b-form-text>
            Short name that describes this webhook. 255 characters max.
          </b-form-text>

        </div>

        <div class="form-group">
          <label for="url">Callback URL:</label>
          <input type="text" class="form-control" id="url" v-model="resource.url" ref="query">

          <b-form-text>
            HTTP POST request will be made to this URL with result batches in its body.
          </b-form-text>

        </div>

        <div class="form-row" v-if="alerts.length">

          <p>Alerts to subscribe to. The number in the (parenthesis) show how many results were captured for that
            particular alert in the past 24 hours.</p>

          <div v-for="alert in alerts" :key="alert.id" class="col-6">

            <b-form-checkbox v-model="selectedAlerts" :value="alert.id" :key="alert.id">
              {{ alert.query }} <span class="text-muted">({{ alert.matches.past_day }})</span>
            </b-form-checkbox>

          </div>

        </div>

        <div class="py-2">
          <hr>
        </div>

        <div class="alert alert-danger my-2" v-if="form_error">
          {{ form_error }}
        </div>

        <div class="text-center" v-if="alerts.length">

          <button type="submit" class="btn btn-primary btn-block" :disabled="isBusy">
            <b-spinner small class="align-middle mr-2" v-if="isBusy"></b-spinner>
            <span>{{ buttonText }}</span>
          </button>

        </div>

        <div v-else>

          <div class="alert alert-danger">
            Create at least one alert first.
          </div>

        </div>

      </form>

    </div>

  </b-modal>

</template>

<script>

import {
  BModal,
  BFormText,
  BSpinner,
  BFormCheckbox
} from 'bootstrap-vue'
import {Util} from "@/classes/Util";
import api from "@/api";
import store from "@/store";

export default {

  components: {
    BModal,
    BFormText,
    BSpinner,
    BFormCheckbox
  },
  props: {
    st: String,
    hook: Object
  },
  data: function () {

    return {
      visible: true,
      title: 'Edit Webhook',
      isBusy: false,
      resource: {},
      selectedAlerts: [],
      buttonText: 'Save Changes',
      form_error: null
    }
  },
  computed: {
    alerts: function () {
      return store.state.alerts;
    }
  },
  watch: {
    hook: function (newValue, oldValue) {

      // will become "reactive" by this point
      this.resource = Util.nonReactive(newValue);

      if (this.alerts) {

        this.selectedAlerts = this.resource.alerts.map(function (alert) {
          return alert.id;
        });

      } else {
        this.selectedAlerts = [];
      }

      if (this.resource.id) {
        this.buttonText = 'Save Changes';
      } else {
        this.buttonText = 'Add new Webhook';
      }
    }
  },
  methods: {
    show: function () {
      this.visible = true;
      this.$refs['modal'].show();
    },
    hide: function () {
      this.$refs['modal'].hide();
    },
    onShow: function () {
      this.form_error = null;
    },
    create: function (item) {

      let oThis = this;
      oThis.isBusy = true;

      api.client.post('/v1/reddit/webhooks', {
        name: this.resource.name,
        url: this.resource.url,
        alert_ids: this.selectedAlerts
      }).then((res) => {

        this.form_error = null;

        this.$emit('hook-created', res.data.data);
        this.hide();

      }).catch((err) => {
        this.form_error = Util.extractErrorMessage(err);
      }).finally(function () {
        oThis.isBusy = false;
      });

    },
    update: function (item) {

      let oThis = this;
      oThis.isBusy = true;

      api.client.patch('/v1/reddit/webhooks/' + this.resource.id, {
        name: this.resource.name,
        url: this.resource.url,
        alert_ids: this.selectedAlerts
      }).then(function (res) {
        oThis.$emit('hook-updated', res.data.data);
        oThis.hide();
      }).catch((err) => {
        this.form_error = Util.extractErrorMessage(err);
      }).finally(function () {
        oThis.isBusy = false;
      });

    },
    submit: function () {

      if (this.resource.id) {
        this.update();
      } else {
        this.create();
      }

    }
  },
  mounted: function () {
    //alert(this.st);
  }
}
</script>

<style scoped>

</style>