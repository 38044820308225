<template>
  <div>

    <div class="mb-4 text-center" v-if="!notificationsEnabled && false">
      <b-button variant="danger" size="md"
                title="When enabled, you will be alerted each time new mentioned get detected."
                @click="enableNotifications">
        Enable Browser Notifications
      </b-button>
    </div>

    <div class="mb-4">
      <div>
        Here are all the new posts on Reddit that match your
        <router-link to="/alerts" class="font-weight-bold">alert settings</router-link>
        within a specified time-frame. Showing only the first 100 results for now.
      </div>
    </div>


    <div class="row">
      <div class="col-md-6">

        <b-list-group id="table_nav">

          <b-list-group-item href="#" :class="{active: !sort}" @click.prevent="sortByDate">
            Newest
          </b-list-group-item>

          <b-list-group-item href="#" :class="{active: sort === 'score'}"
                             @click.prevent="sortByScore">
            Most Popular
          </b-list-group-item>

          <b-list-group-item href="#" :class="{active: sort === 'num_comments'}"
                             @click.prevent="sortByComments">
            Most Commented
          </b-list-group-item>

        </b-list-group>

      </div>

      <div class="col-6">

        <div class="form-group form-inline pt-1">
          <label for="time_filter" class="pr-2">Time Range:</label>
          <b-form-select v-model="selected" :options="options" id="time_filter"></b-form-select>

          <b-form-select v-model="selectedAlert" :options="alertOptions" class="ml-3"></b-form-select>

          <b-button variant="outline-primary" size="md" class="ml-5" @click="refreshThreads">
            <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
          </b-button>

        </div>

      </div>

    </div>

    <div class="row pb-2" v-if="false">
      <div class="col-12">

        <input type="text" placeholder="filter further...">

        Total results: <span>{{ items.length }}</span>
      </div>
    </div>

    <b-table striped hover show-empty outlined :fields="fields" :items="items" :busy="isBusy">

      <template v-slot:table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>

      <template v-slot:empty>
        <div class="text-center p-4">No results captured yet. Check back later!</div>
      </template>

      <template v-slot:cell(title)="data">

        <b-badge variant="success mr-1" v-if="data.item.is_new">New</b-badge>

        <a :href="data.item['short_link']" target="_blank" class="result__title" v-html="data.value"></a>

        <div class="snippet" v-if="data.item.text_snippet" v-html="data.item.text_snippet"></div>

        <div class="small">
          {{ data.item.score }} points &ndash;

          <span :class="{'font-weight-bold': data.item['num_comments'] > 0}">
                            {{ data.item['num_comments'] }}
                        </span> comments &ndash;

          <span class="text-muted" :title="data.item['updated_ago']">{{ data.item['date_ago'] }}</span> &ndash;
          <span class="text-danger" v-if="data.item['subreddit']">/r/{{ data.item['subreddit']['name'] }}</span>
          <span :class="data.item.sub_count_classes" v-if="data.item.sub_count_text">
            ({{ data.item.sub_count_text }})
          </span>
        </div>

      </template>

    </b-table>

  </div>

</template>

<script>

import {
  BListGroup,
  BListGroupItem,
  BSpinner,
  BTable,
  BButton,
  BBadge,
  BFormSelect,
  BIconArrowClockwise
} from 'bootstrap-vue'
import api from "@/api";
import {Notifier} from "@/classes/Notifier";
import store from "@/store";

export default {
  name: 'Watch',
  components: {
    BTable,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BButton,
    BBadge,
    BFormSelect,
    BIconArrowClockwise
  },
  data: function () {
    return {
      notificationsEnabled: true,
      isBusy: false,
      latestThreadId: null,
      sort: null,
      fields: [
        {key: 'title', title: 'Title'}
      ],
      items: [],

      selected: '1d',
      options: [
        {value: '1h', text: 'Past hour'},
        {value: '3h', text: '3 hours'},
        {value: '6h', text: '6 hours'},
        {value: '1d', text: '24 hours'},
        {value: '48h', text: '48 hours'}
      ],

      selectedAlert: 0,
      alertOptions: []
    }
  },
  watch: {
    selected: function () {
      this.loadItems()
    },
    selectedAlert() {
      this.loadItems();
    },
    user: {
      immediate: true,
      handler: function (newValue) {

        if (newValue) {
          this.loadItems();
        }
      }
    },
    sort: function () {
      this.loadItems();
    }
  },
  methods: {
    notify(count) {

      var notification = new Notification('Reddit Tracker', {
        body: 'New threads: ' + count,
        // icon: null,
        requireInteraction: false,
        silent: false
      });

    },
    test() {

    },
    enableNotifications() {

      const oThis = this;

      Notification.requestPermission(function (permission) {

        if (permission === 'granted') {
          //alert('Success!');
          oThis.notificationsEnabled = true;
          return;
        }

        // denied or default
        alert('Permission denied. You must enable Browser Notifications manually now.');
      });
    },
    sortByDate() {
      this.sort = null;
      this.latestThreadId = null;
    },
    sortByScore() {
      this.sort = 'score';
      this.latestThreadId = null;
    },
    sortByComments() {
      this.sort = 'num_comments';
      this.latestThreadId = null;
    },
    refreshThreads: function () {
      this.loadItems();
    },
    loadItems: function () {

      const oThis = this;

      oThis.isBusy = true;

      let params = {
        'sort': oThis.sort,
        'time_range': oThis.selected,
        'alert_id': oThis.selectedAlert
      };

      if (!oThis.sort) {
        //params['after'] = oThis.latestThreadId;
      }

      api.client.get('/v1/reddit/results', {
        params: params
      }).then(function (res) {

        const data = res.data;

        // new threads since first loaded
        if (oThis.latestThreadId) {
          console.log('new threads found: ' + data.data.length);

          if (data.data.length) {
            console.log(data.data);
            oThis.notify(data.data.length);
          }
        }

        let last_post_id = oThis.latestThreadId;

        let rows = data.data;

        if (last_post_id) {
          //rows.reverse();
        }

        oThis.items = [];


        rows.forEach(function (item, index) {

          if (index === 0) {
            last_post_id = item.id;
          }

          let text_snippet = item.text_snippets ? item.text_snippets[0] : null;

          let sub_count_text = null;
          let sub_count_classes = 'ml-1 text-info smaller';

          if (item.subreddit) {

            let sub_count = item.subreddit.subscriber_count;

            if (sub_count) {
              sub_count_text = '~' + item.subreddit.subscriber_count_formatted + ' subs';
            }

            if (sub_count >= 1000000) {
              sub_count_classes = 'ml-1 mil font-weight-bold';
            } else if (sub_count >= 100000) {
              sub_count_classes = 'ml-1 hundred_k';
            }

          }

          let date_ago = window.luxon.fromSeconds(item.created_utc).toRelative();
          let updated_ago = window.luxon.fromSeconds(item.updated_utc).toRelative();

          let row = {
            is_new: false, //oThis.latestThreadId !== null && !oThis.sort,
            subreddit: item.subreddit,
            sub_count_text: sub_count_text,
            sub_count_classes: sub_count_classes,
            title: item.title_highlighted,
            text_snippet: text_snippet,
            score: item.score,
            num_comments: item.num_comments,
            short_link: item.short_link,
            date_ago: date_ago,
            updated_ago: updated_ago
          };

          if (oThis.latestThreadId === 8) {
            oThis.items.unshift(row);
          } else {
            oThis.items.push(row);
          }

        });

        oThis.latestThreadId = last_post_id;

      })
          .catch(function (err) {
            alert(err)
          })
          .then(function () {
            oThis.isBusy = false;
          });
    }
  },
  mounted: function () {

    const oThis = this;

    store.dispatch('syncAlerts').then(function (alerts) {

      const options = alerts.map(function (alert) {
        return {
          value: alert.id,
          text: alert.query
        }
      });

      options.unshift({
        value: 0,
        text: 'All alerts'
      });

      oThis.alertOptions = options;

    });

    // periodically check if notifications are enabled
    setInterval(function () {
      oThis.notificationsEnabled = Notifier.notificationsAllowed();
    }, 1500);


  }
}
</script>

<style lang="scss" scoped>

#table_nav {
  @extend .list-group-horizontal, .justify-content-center, .text-center, .mb-2;
  cursor: pointer;
}

.result__title {
  /*        @extend .text-primary;*/

  font-family: Helvetica, sans-serif;
  color: #0000ff;
  font-size: 1rem;
}

.snippet {
  color: #494949;
  font-size: 0.9rem;
}

</style>