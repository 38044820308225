import VueRouter from 'vue-router'
import Home from "@/components/Home";
import Watch from "@/components/Watch";
import Alerts from "@/components/Alerts";
import Settings from "@/components/Settings";
import NotFound from "@/components/NotFound";
import {setPageTitleMiddleware} from "@/routing/setPageTitleMiddleware";
import {AuthMiddleware} from "@/routing/AuthMiddleware";

const routes = [
    {
        name: 'home', path: '/', component: Home, meta: {
            title: null
        }
    },
    {
        name: 'posts', path: '/posts', component: Watch, meta: {
            title: 'Posts',
            auth: true
        }
    },
    {
        name: 'alerts', path: '/alerts', component: Alerts, meta: {
            title: 'Alerts',
            auth: true
        }
    },
    {
        name: 'settings', path: '/settings', component: Settings, meta: {
            title: 'Settings',
            auth: true
        }
    },
    {
        name: 'not_found',
        path: '*',
        component: NotFound
    }
];

// use modal loader for loading

// fade in/out between page loads
const router = new VueRouter({
    linkActiveClass: 'active',
    routes: routes,
    mode: 'history'
});

router.beforeEach((to, from, next) => {

    const fullPath = to.fullPath;

    const matches = fullPath.match(/access_token=([a-z0-9_]+)/i);

    if (matches !== null) {
        const token = matches[1];

        console.log(`Found token: ${token}`);

        localStorage.setItem('token', token);
        window.location = '/alerts';

        // next({name: 'alerts'});
    } else {
        next();
    }

})

router.beforeEach(AuthMiddleware);
router.beforeEach(setPageTitleMiddleware);

export default router;